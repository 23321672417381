import React from "react"
import { Button } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';


const CustomButton = withStyles(theme => ({
    root: {
        boxShadow: '0 4px 8px 0 rgb(44 39 56 / 20%)',
        padding: '10px 17px 13px 20px',
        borderRadius: 5,
        backgroundColor: '#f67347',
        color: 'white',
        fontFamily: 'Poppins-Regular',
        fontWeight: 'bolder',
        textTransform: 'inherit',
        '&:hover' : {
            backgroundColor: '#c75c38'
        },
    },
}))(Button);

export const OrangeButtonPolicyManagment = ({children, className, ...props}) => {
    return (
        <CustomButton {...props} variant="contained" disableElevation className={`${className}`}>{children}</CustomButton>
    )
}

