import ApiService, { HTTPMethod } from "./api.core";
import { Transformer } from "../models/Transformer";
import { isToday, getAiWithoutId, equivalentAdditionalInsured, removeDuplicateAdditionalInsured, isTodayBreakdown } from "../services/utils";
import { FirebaseService, FirebaseConfigKey } from "../services/FirebaseService";
import { COUNTRIES } from "../Constants";
import { SessionService } from "../services/SessionService";
import { COVERAGE_TYPES } from "../models/insurance-models/CoverageType";
import { WorldWideOption } from "../models/insurance-models/WorldWideOption";


class InsuranceApi {

    async submitAviationForm(forminformation) {
        let res = await ApiService.fetch('aviationformsubmit', HTTPMethod.POST, forminformation)
        return res
    }

    async getActiveInsurances() {
        let res = await ApiService.fetch("insurances/me/active");
        if (res.ok && res.data && res.data.length > 0) {
            let activeInsurance = Transformer.parseActiveInsuranceModel(res.data[0])
            res.parsedData = activeInsurance;
        }
        return res;
    }

    async purchaseInsurance(optionId, additionalInsured, autoRenew, externalActorId, paymentMethod) {
        let body = {}; 
        body.offer_id = optionId;
        body.auto_renew = !!autoRenew;
        body.payment_method = paymentMethod
        
        if(externalActorId) {
            body.external_actor_id = externalActorId
        }

        if (additionalInsured) {
          body.additional_insureds = removeDuplicateAdditionalInsured(additionalInsured.map(q => getAiWithoutId(q)));
        }
        
        let res = await ApiService.fetch("insurances/", HTTPMethod.POST, body);
        
        if (res.ok) {
          let activeInsurance = Transformer.parseActiveInsuranceModel(res.data)
          res.parsedData = activeInsurance;
        }
        return res;
    }

    async cancelInsurance(data) {
        let res = await ApiService.fetch("insurances/cancel", HTTPMethod.POST, data);
        return res;
    }

    async beforeMidtermInsurance(data) {
        let res = await ApiService.fetch("insurances/before-midterm", HTTPMethod.POST, data);
        return res;
    }

    async midtermInsurance(data) {
        let res = await ApiService.fetch("insurances/midterm", HTTPMethod.POST, data);
        return res;
    }

    async sendAQuote(insurancePurchaseContext, offersContext, emails, companyName, ) {

        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
            emails: emails, 
            name: companyName, 
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits: offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            custom_premium: offersContext.specialPrice.custom_premium,
            custom_premium_token: offersContext.specialPrice.custom_premium_token,
            indoor_coverage: offersContext.indoorCoverage.included,
            indoor_coverage_description: offersContext.indoorCoverage.description
        }
        if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
            body["start_time"] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch('insurances/quote', HTTPMethod.POST, body)
    }

    async saveQuoteState(insurancePurchaseContext, offersContext) {
        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits: offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            custom_premium: offersContext.specialPrice.custom_premium,
            custom_premium_token: offersContext.specialPrice.custom_premium_token,
            indoor_coverage: offersContext.indoorCoverage.included,
            indoor_coverage_description: offersContext.indoorCoverage.description
        }
        if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
            body["start_time"] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch('insurances/purchaseState', HTTPMethod.POST, body)
    }

    async referQuote(insurancePurchaseContext, offersContext) {
        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits: offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            custom_premium: offersContext.specialPrice.custom_premium,
            custom_premium_token: offersContext.specialPrice.custom_premium_token,
            indoor_coverage: offersContext.indoorCoverage.included,
            indoor_coverage_description: offersContext.indoorCoverage.description
        }
        if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
            body["start_time"] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch('insurances/refer-quote', HTTPMethod.POST, body)
    }

    async getOdometerUploadLink(insuranceId) {
        return await ApiService.fetch(`insurances/${insuranceId}/odometer/odometer_upload_link`, HTTPMethod.POST)
    }

    async getInsurances() {
        return await ApiService.fetch('insurances', HTTPMethod.GET)
    }

    async toggleAutoRenew(policyId) {
        return await ApiService.fetch(`insurances/toggle-auto-renew/${policyId}`, HTTPMethod.POST)
    }

    async checkIfNeedToRecharge() {
        return await ApiService.fetch('insurances/need-to-recharge',HTTPMethod.GET)
    }

    async getFailedPayements() {
        return await ApiService.fetch('insurances/failed-payments',HTTPMethod.GET)
    }

    async rechargeFailedPayments(paymentId) {
        return await ApiService.fetch('insurances/recharge-failed-payment',HTTPMethod.POST, {
            payment_id: paymentId
        })
    }

    async rechargeUser() {
        return await ApiService.fetch('insurances/recharge-user',HTTPMethod.POST)
    }

}

export default new InsuranceApi()