import React, { useReducer, createContext, useContext } from 'react';

const MIDTERM_ACTIONS = {
    START_MIDTERM: 'START_MIDTERM',
    FINISH_MIDTERM: 'FINISH_MIDTERM'
};

const defaultState = {
    policy: null,
    isOpen: false
};

const reducer = (state, action) => {
    const payload = action.payload;
    switch (action.type) {
        case MIDTERM_ACTIONS.START_MIDTERM:
            return {
                ...state,
                policy: payload.policy,
                isOpen: true
            };
        case MIDTERM_ACTIONS.FINISH_MIDTERM:
            return {
                ...state,
                policy: null,
                isOpen: false
            };
        default:
            return state;
    }
};

export const MidtermContextStore = createContext(defaultState);

const MidtermContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const startMidterm = (policy) => {
        dispatch({
            type: MIDTERM_ACTIONS.START_MIDTERM,
            payload: { policy }
        });
    };

    const finishMidterm = () => {
        dispatch({
            type: MIDTERM_ACTIONS.FINISH_MIDTERM
        });
    };

    return (
        <MidtermContextStore.Provider
            value={{ ...state, startMidterm, finishMidterm }}
        >
            {children}
        </MidtermContextStore.Provider>
    );
};

export const useMidterm = () => useContext(MidtermContextStore);

export default MidtermContext;
