import * as yup from 'yup';

const midtermFormSchema = yup
.object()
    .shape({
        transaction_type: yup.string().required().typeError('Transaction type is required'),
        amount: yup.number().required().typeError('Midterm amount is required'),
        surcharge: yup.number().required().typeError('Midterm surchagre is required'),
        quote_number: yup.string().required().typeError('Quote number is required'),
})
.required();

export default midtermFormSchema;