import { Container, Grid, MenuItem, Typography, useMediaQuery } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useMidterm } from '../../../context/MidtermContext/MidtermContext';
import { SkywatchDialog } from '../../PopUps/SkywatchDialog';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ControlledTextField } from '../../Core/Form/ControlledFields';
import midtermFormSchema from '../schemas/MidtermFormSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrangeButton } from '../../Core/Buttons/OrangeButton';
import insuranceApi from '../../../api/insurance.api';
import { LoadingContextStore } from '../../../context/LoadingContext/LoadingContext';
import { AlertContextStore } from '../../../context/AlertContext/AlertContext';
import { OrangeButtonPolicyManagment } from '../../Core/Buttons/OrangeButtonPolicyManagment';
import { re } from '../../Profile/schemas/userFormSchema';
import { set } from 'date-fns';
import { BackButton } from '../../Core/Buttons/BackButton.js';
import { BackButtonTitle } from '../../Core/BackButtonTitle';
import paymentApi from '../../../api/payment.api.js';
import { PAYMENT_STATUS } from '../../../context/QuoteContext/actions.js';

const MidtermModal = ({fetchPolicies}) => {
    const { policy, isOpen, finishMidterm } = useMidterm();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { awaitWithLoading, setLoading } = useContext(LoadingContextStore);
    const {showError, showInfo} = useContext(AlertContextStore);
    const [isOpenApprovePopup, setApproveMidtermPopup]= useState(false)
    const [midtermDescription, setmidtermDescription]= useState("")
    const [quoteMidtermId, setquoteMidtermId]= useState("")





    const form = useForm({
        mode: 'onSubmit',
        defaultValues: {
            amount: null,
            transaction_type: null,
            quote_number: null,
            surcharge: null,
        },
        resolver: yupResolver(midtermFormSchema)
    });

    useEffect(() => {
        if (!policy) return;
        form.reset({
            amount: null,
            transaction_type: null,
            quote_number: null,
            surcharge: null ,
        });
    }, [policy]);



    const onSubmitLoadMidterm = async (data) => {        
        const loadMidterm = async () => {
            const res = await awaitWithLoading(insuranceApi.beforeMidtermInsurance({insurance_id: policy.policy_id, ...data}));
            if( res.status === 400 ){
                showError({title: res.data.title});
                await finishMidterm();
            } else if (res.ok) {
                setmidtermDescription(res.data.midterm_description)
                setquoteMidtermId(res.data.midterm_quote_id)
                setApproveMidtermPopup(true)
            } else {
                showError({title: "Error while trying to do midterm"});
                await finishMidterm();
            }
        }
        await awaitWithLoading(loadMidterm())

    };


    const onSubmitApproveMidterm = async (data) => {        
        const approveMidterm = async () => {
            setLoading(true)
            data.midterm_quote_id = quoteMidtermId
            const res = await insuranceApi.midtermInsurance({insurance_id: policy.policy_id, ...data});
            if(res.ok) {
                const { transaction_id } = res.data;
                    const checkStatus = async () => {
                        const { data } = await paymentApi.checkChargeStatus(transaction_id, quoteMidtermId); 
                        switch(data.status) {
                            case PAYMENT_STATUS.SUCCESS:
                            case PAYMENT_STATUS.REFUNDED:
                                setLoading(false)
                                showInfo({title: "midterm successfully",  ignoreCancel: true});
                                break;
                            case PAYMENT_STATUS.PENDING:
                            case PAYMENT_STATUS.CREATED:
                                setTimeout(checkStatus, 100)
                                break;
                            case PAYMENT_STATUS.FAILED:
                                setLoading(false)
                                showError({title:"We couldn’t process your payment, please try another payment method.",message: "Payment failed"});
                                break;
                            default:
                                setLoading(false)
                                showError({title:"We couldn’t process your payment, please try another payment method.",message: "Payment failed"});
                                break;
                        }
                    }
                    setTimeout(async () => {
                        await checkStatus();
                    }, 100)
               
            } 
            else if( res.status === 400 ){
                setLoading(false)
                showError({title: res.data.title});
            } else {
                setLoading(false)
                showError({message: "Error has occured"});
                return;
            }
            
        }

        await approveMidterm()
        setApproveMidtermPopup(false)
        await finishMidterm();
    };

    const cancelMidterm = async (data) => {
        const cancel = async () => {
           setApproveMidtermPopup(false)
           await finishMidterm();
        }

        await awaitWithLoading(cancel())
    }

    return (
        <><SkywatchDialog
            fullScreen={isMobile}
            open={isOpen}
            closeDialog={cancelMidterm}
            maxWidth={'xs'}
        >
            <div className="margin-top-basic margin-bottom-basic">
                <Typography variant="h3" className="margin-bottom-basic" color="primary" style={{ textAlign: 'center' }}>
                    Load midterm from MyGlobal
                </Typography>
                <Container>
                    <form onSubmit={form.handleSubmit(onSubmitLoadMidterm)}>
                        <FormProvider {...form}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    className="margin-bottom-small"
                                >
                                    <Typography variant="h4" className="grey">
                                        Quote number
                                    </Typography>
                                    <ControlledTextField
                                        disableFuture
                                        name="quote_number"
                                        className="margin-top-8"
                                        fullWidth
                                        error={!!form.formState.errors.quote_number} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className="margin-bottom-small"
                                >
                                    <Typography variant="h4" className="grey">
                                        Charge / Refund
                                    </Typography>
                                    <Controller
                                        name="transaction_type"
                                        control={form.control}
                                        render={({ field }) => (
                                            <ControlledTextField fullWidth={true} name="transaction_type" id="transaction_type" className="margin-top-8" placeholder="Please select" select>
                                                <MenuItem value="Charge"><span>Charge</span></MenuItem>
                                                <MenuItem value="Refund">Refund</MenuItem>
                                            </ControlledTextField>
                                        )} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className="margin-bottom-small"
                                >
                                    <Typography variant="h4" className="grey">
                                        Amount
                                    </Typography>
                                    <ControlledTextField
                                        name="amount"
                                        type="number"
                                        fullWidth={true}
                                        className="margin-top-8"
                                        placeholder="Amount"
                                        error={!!form.formState.errors.amount} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className="margin-bottom-small"
                                >
                                    <Typography variant="h4" className="grey">
                                        Surcharge
                                    </Typography>
                                    <ControlledTextField
                                        name="surcharge"
                                        type="number"
                                        fullWidth={true}
                                        className="margin-top-8"
                                        placeholder="Surcharge"
                                        error={!!form.formState.errors.surcharge} />
                                </Grid>
                            </Grid>
                        </FormProvider>
                        <Grid container justifyContent="center">
                            <OrangeButtonPolicyManagment type="submit" fullWidth>Update</OrangeButtonPolicyManagment>
                        </Grid>
                    </form>
                </Container>
            </div>
        </SkywatchDialog><SkywatchDialog
            fullScreen={isMobile}
            open={isOpenApprovePopup}
            closeDialog={cancelMidterm}
            maxWidth={'xs'}
        >
                <div className="margin-top-basic margin-bottom-basic">
                    <Typography variant="h3" className="margin-bottom-basic" color="primary" style={{ textAlign: 'center' }}>
                        Confirmation
                    </Typography>
                    <Container>
                        <form onSubmit={form.handleSubmit(onSubmitApproveMidterm)}>
                            <FormProvider {...form}>
                                <Grid container>
                                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                                        <Typography variant='body1'>
                                        Policy Number: {policy?.policy_number}
                                        </Typography>
                                    </Grid>    
                                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                                        <Typography variant='body1'>
                                        Quote Number: {form.getValues()['quote_number']}
                                        </Typography>
                                    </Grid>    
                                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                                        <Typography variant='body1'>
                                        Transaction Type: {form.getValues()['transaction_type']}
                                        </Typography>
                                    </Grid>    
                                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                                        <Typography variant='body1'>
                                        Midterm Amount: ${form.getValues()['amount']}
                                        </Typography>
                                    </Grid>    
                                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                                        <Typography variant='body1'>
                                        Surcharge: ${form.getValues()['surcharge']}
                                        </Typography>
                                    </Grid>    
                                </Grid>
                            </FormProvider>
                            <Grid container >
                                <OrangeButtonPolicyManagment type="submit" fullWidth>Confirm</OrangeButtonPolicyManagment>
                            </Grid>
                        </form>
                    </Container>
                </div>
            </SkywatchDialog></>
    );
};

export default MidtermModal;
