import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { PolicyContextStore } from '../../../context/PolicyContext/PolicyContext';
import PolicyCard from './PolicyCard';
import { POLICY_STATUS } from '../../../context/PolicyContext/types';
import PolicyCardSkeleton from './PolicyCardSkeleton';
import CancellationContext, { useCancellation } from '../../../context/CancellationContext/CancellationContext';
import CancellationModal from '../PolicyCancellation/CancellationModal';
import MidtermModel from '../PolicyMidterm/MidtermModal'
import useFetchPolicies from '../../../hooks/useFetchPolicies';
import MidtermContext from '../../../context/MidtermContext/MidtermContext';

const ActivePolicies = () => {
    const { active_policies, scheduled_policies, cancelled_policies, pending_policies, policiesExist } = useContext(PolicyContextStore);
    const { isLoading, fetchPolicies} = useFetchPolicies()

    return (
        <CancellationContext>
            <MidtermContext>
            <div>
                {!isLoading && active_policies.map(policy => <PolicyCard key={policy.policy_number} policy={policy} type={POLICY_STATUS.ACTIVE}/>)}
                {!isLoading && scheduled_policies.map(policy => <PolicyCard key={policy.policy_number} policy={policy} type={POLICY_STATUS.SCHEDULED}/>)}
                {!isLoading && cancelled_policies.map(policy => <PolicyCard key={policy.policy_number} policy={policy} type={POLICY_STATUS.CANCELED}/>)}
                {!isLoading && pending_policies.map(policy => <PolicyCard key={policy.policy_number} policy={policy} type={POLICY_STATUS.PENDING}/>)}
                {!policiesExist && !isLoading && <Typography className="no-policies-text">No Policies</Typography>}
                {isLoading && <div>
                    <PolicyCardSkeleton lineAmount={7}/>  
                </div>}
                <CancellationModal fetchPolicies={fetchPolicies}/>
                <MidtermModel fetchPolicies={fetchPolicies}></MidtermModel>
            </div>
            </MidtermContext>
        </CancellationContext>

    );
};

export default ActivePolicies;
